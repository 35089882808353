<template>
	<div class="content">
		<img class="bg" src="https://pzdsoss.pzds.com/h5/mm_bg/mayi_bg.png" alt="" />
		<img class="bg" src="https://pzdsoss.pzds.com/h5/mm_bg/mayi_bg1.png" alt="" />
		<div class="page-container">
			<div class="input_box">
				<div class="input">
					<van-field v-model="action.phone" type="number" placeholder="请输入注册手机号" />
				</div>
				<div class="input">
					<van-field v-model="action.smsCode" type="text" placeholder="请输入验证码">
						<template #button>
							<div v-if="isShow" class="code" @click="getCheck">获取验证码</div>
							<div v-else class="code">{{ count }}s</div>
						</template>
					</van-field>
				</div>
				<div class="btn" @click="login">注册</div>
			</div>
			<div class="textBox dis-flex flex-y-start">
				<img v-if="!radio" src="../assets/img/radio1.png" alt="" @click="radio = true" />
				<img v-else src="../assets/img/radio1_a.png" alt="" @click="radio = false" />
				<div>
					未注册手机验证后自动创建盼之账户 。点击注册即表示您已阅读并同意
					<span style="color: #333" @click="$router.push('/readme')">《盼之代售用户协议》</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import testApi from '@/api/test';
import { showToast } from 'vant';
export default {
	data() {
		return {
			action: {},
			radio: false,
			isShow: true,
			count: 60,
			times: null,
		};
	},
	methods: {
		login() {
			if (!this.action.phone) return showToast('请输入手机号');
			if (!this.action.smsCode) return showToast('请输入验证码');
			this.$emit('login', this.action);
		},
		getCheck() {
			if (!this.action.phone) return showToast('请输入手机号');
			this.$emit('getCheck', this.action.phone);
			testApi.check({ action: { phone: this.action.phone, smsTemplate: 'LOGIN' } }).then(res => {
				if (res.code == 'SUCCESS') {
					showToast('发送成功');
					this.verification();
				} else {
					showToast(res.info);
				}
			});
		},
		verification() {
			this.isShow = false; // 倒计时
			this.count = 60; // 赋值60秒
			this.times = setInterval(() => {
				this.count--;
				if (this.count <= 0) {
					this.isShow = true;
					clearInterval(this.times);
				}
			}, 1000);
		},
	},
};
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	position: relative;
	.bg {
		width: 100%;
		height: auto;
	}
	.page-container {
		position: absolute;
		bottom: 0.61rem;
		left: 0;
		right: 0;
		padding: 0 0.56rem;
		.input_box {
			background: #ffffff;
			box-shadow: 0px 0.04rem 0.27rem 1px rgba(0, 0, 0, 0.16);
			border-radius: 0.4rem;
			padding: 0.67rem 0.4rem;
			box-sizing: border-box;
			.input {
				height: 1.2rem;
				background: rgba(247, 247, 245, 0.64);
				box-shadow: inset 0px 0.04rem 0.08rem 1px rgba(0, 0, 0, 0.16);
				border-radius: 0.4rem;
				margin-bottom: 0.56rem;
				.code {
					font-weight: 600;
					color: #333333;
					font-size: 0.37rem;
				}
			}
			.btn {
				height: 1.2rem;
				background: linear-gradient(180deg, #ffd400 0%, #ffcf3b 100%);
				box-shadow: 0px 0.07rem 10px 0.13rem rgba(0, 0, 0, 0.16);
				border-radius: 0.4rem;
				opacity: 1;
				text-align: center;
				line-height: 1.2rem;
				font-weight: 600;
				color: #333333;
				font-size: 0.37rem;
			}
			/deep/.van-cell {
				height: 1.2rem;
				background: rgba(247, 247, 245, 0.64);
				border-radius: 0.4rem;
				padding: 0 0.4rem;
				display: flex;
				align-items: center;
			}
			/deep/.van-field__control {
				color: #999;
				font-size: 0.37rem;
				font-weight: 300;
			}
			::-webkit-input-placeholder {
				color: #999;
				font-weight: 300;
			}
		}
		.textBox {
			margin: 0.27rem 0 0 0;
			font-weight: 300;
			color: #999999;
			font-size: 0.3rem;
			img {
				width: 0.32rem;
				height: 0.32rem;
				margin-right: 0.13rem;
			}
		}
	}
}
</style>
